<template>
  <form
    action="#"
    method="POST"
    @submit.prevent="onSubmit"
    @keydown="form.errors.clear($event.target.name)"
  >
    <v-card>
      <v-card-body>
        <div class="mb-6">
          <v-label for="current_password"> Parola curenta </v-label>
          <v-input
            type="password"
            name="current_password"
            v-model="form.current_password"
            autocomplete="current-password"
            class="w-full"
          />
          <v-error :error="form.errors.get('current_password')" />
        </div>

        <div class="mb-6">
          <v-label for="password"> Noua parola </v-label>
          <v-input
            type="password"
            name="password"
            v-model="form.password"
            autocomplete="new-password"
            class="w-full"
          />
          <v-error :error="form.errors.get('password')" />
        </div>

        <div>
          <v-label for="password_confirmation"> Confirma parola </v-label>
          <v-input
            type="password"
            name="password_confirmation"
            v-model="form.password_confirmation"
            autocomplete="new-password"
            class="w-full"
          />
          <v-error :error="form.errors.get('password_confirmation')" />
        </div>
      </v-card-body>
      <v-card-footer class="text-right">
        <v-button-primary type="submit" :disabled="form.loading">
          <v-button-icon v-if="form.loading">
            <loader :radius="5" />
          </v-button-icon>
          Salveaza
        </v-button-primary>
      </v-card-footer>
    </v-card>
  </form>
</template>

<script>
import Form from "@/core/Form";
import passwordsApi from "@/api/resources/passwordsApi";
import VCard from "./ui/VCard.vue";
import VCardBody from "./ui/VCardBody.vue";
import VCardFooter from "./ui/VCardFooter.vue";
import VError from "./ui/VError.vue";
import VInput from "./ui/VInput.vue";
import VLabel from "./ui/VLabel.vue";
import VButtonIcon from "./ui/VButtonIcon.vue";
import Loader from "./Loader.vue";
import VButtonPrimary from "./ui/VButtonPrimary.vue";

export default {
  components: {
    VLabel,
    VError,
    VInput,
    VCardFooter,
    VCard,
    VCardBody,
    VButtonIcon,
    Loader,
    VButtonPrimary,
  },
  data() {
    return {
      form: new Form({
        password: "",
        current_password: "",
        password_confirmation: "",
      }),
    };
  },
  methods: {
    onSubmit() {
      this.form.submitService(passwordsApi, "update").then(() => {
        this.form.message = "Parola a fost actualizata";
      });
    },
  },
};
</script>

<style></style>
