<template>
  <div class="border-b border-gray-200 dark:border-gray-700">
    <ul class="flex flex-wrap -mb-px">
      <li class="mr-2" v-for="tab in tabs" :key="tab.href">
        <router-link
          class="
            inline-block
            py-4
            px-4
            text-sm
            font-medium
            text-center
            rounded-t-lg
            border-b-2
          "
          :to="tab.href"
          :class="{
            'text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300':
              !tab.current,

            'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500':
              tab.current,
          }"
        >
          {{ tab.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: String,
    },
  },
  data() {
    return {
      tabs: [
        {
          name: "Cereri",
          href: "/contul-meu",
          current: false,
        },
        // { name: "Cereri", href: "/cereri", current: false },
        { name: "Comenzi", href: "/comenzi", current: false },
        { name: "Setari", href: "/setari", current: false },
      ],
    };
  },

  watch: {
    current(newValue) {
      this.updateCurrentTab(newValue);
    },
  },

  methods: {
    updateCurrentTab(to) {
      this.tabs = this.tabs.map((tab) => {
        return {
          ...tab,
          current: `/${to}` === tab.href,
        };
      });
    },
  },

  created() {
    this.updateCurrentTab(this.current);
  },
};
</script>

<style></style>
