<template>
  <app-layout>
    <v-container>
      <user-tabs current="setari" />
      <page-section>
        <settings-section-password />

        <settings-section-separator />
      </page-section>
    </v-container>
  </app-layout>
</template>

<script>
import PageSection from '../components/PageSection.vue';
import SettingsSectionPassword from "../components/SettingsSectionPassword.vue";
import SettingsSectionSeparator from "../components/SettingsSectionSeparator.vue";
import UserTabs from "../components/UserTabs.vue";
import VContainer from "../components/VContainer.vue";
import AppLayout from "../layout/AppLayout.vue";

export default {
  components: {
    AppLayout,
    SettingsSectionSeparator,
    SettingsSectionPassword,
    UserTabs,
    VContainer,
    PageSection,
  },
};
</script>

<style></style>
