<template>
  <settings-section>
    <template v-slot:title> Parola </template>
    <template v-slot:subtitle>
      Asigura-te ca folosesti o parola lunga pentru a ramane in siguranta
    </template>
    <settings-section-password-form />
  </settings-section>
</template>

<script>
import SettingsSection from "./SettingsSection.vue";
import SettingsSectionPasswordForm from './SettingsSectionPasswordForm.vue';
export default {
  components: { SettingsSection, SettingsSectionPasswordForm },
};
</script>

<style>
</style>